<template lang="html">
  <section class="team">
    <bannerHead dataTitle="Equipo"></bannerHead>
    <breacrumbs dataTitle="Equipo"></breacrumbs>
    <section class="organigrama"
      v-if="Object.entries(store.organigrama['0']).length != 0 || Object.entries(store.organigrama['1']).length != 1 || Object.entries(store.organigrama['0']).length != 2">
      <div class="container">
        <h1 class="title-line initial">Así nos organizamos</h1>
        <template v-if="Object.entries(store.organigrama['0']).length != 0">
          <div class="container-first-level">
            <div class="card" v-for="(person) in store.organigrama['0']" :key="person.id">
              <router-link :to="{
                name: 'team-member',
                params: {
                  id: person.id
                }
              }">
                <h2 class="subtitle">Dirección General</h2>
                <img class="img-card" :src="person.image.url" :alt="person.name + person.surnames">
                <h3 class="-center -small">{{ person.name }} {{ person.surnames }}</h3>
                <p class="subtitle">{{ person.department_position }}</p>
              </router-link>
              <a class="subtitle-2" :href="'mailto:' + person.email">{{ person.email }}</a>
            </div>
          </div>
        </template>

        <template v-if="Object.entries(store.organigrama['1']).length != 0">
          <h2 class="subtitle">Directores y Responsables de Área</h2>
          <div class="container-first-level">
            <div class="card" v-for="(departament, key) in store.organigrama['1']" :key="key">
              <div v-for="(personDepart, idx) in Object.values(departament)" :key="idx">
                <router-link :to="{
                  name: 'team-member',
                  params: {
                    id: personDepart.id
                  }
                }">
                  <img class="img-card" :src="personDepart.image.url" :alt="personDepart.name + personDepart.surnames">
                  <h3 class="-center -small">{{ personDepart.name }} {{ personDepart.surnames }}</h3>
                  <p class="subtitle">{{ personDepart.department_position }} {{ personDepart.department.name }}</p>
                </router-link>
                <a class="subtitle-2" :href="'mailto:' + personDepart.email">{{ personDepart.email }}</a>
              </div>
            </div>
          </div>
        </template>

        <template v-if="Object.entries(store.organigrama['2']).length != 0">
          <h2 class="subtitle">Equipos</h2>
          <div class="container-first-level">
            <div class="card" v-for="(personDepart, idx) in this.departament" :key="idx">
              <router-link :to="{
                name: 'team-member',
                params: {
                  id: personDepart.id
                }
              }">
                <img class="img-card" :src="personDepart.image.url" :alt="personDepart.name + personDepart.surnames">
                <h3 class="-center -small">{{ personDepart.name }} {{ personDepart.surnames }}</h3>
                <p class="subtitle">{{ personDepart.department_position }}</p>
              </router-link>
              <a class="subtitle-2" :href="'mailto:' + personDepart.email">{{ personDepart.email }}</a>
            </div>
          </div>
        </template>
      </div>
    </section>
  </section>
</template>

<script lang="js">
import bannerHead from '@/components/banner-head.vue'
import breacrumbs from '@/components/breacrumbs.vue'
import { contentStore } from '@/stores/contents'
const store = contentStore();
export default {
  name: 'team',
  props: [],
  components: {
    bannerHead,
    breacrumbs
  },
  setup() {
    return {
      store
    }
  },
  mounted() {
    store.loadAllDepartments()
    store.loadEmployees();
  },
  methods: {
    singleArray(arr) {
      let res = []
      arr.forEach(val => {
        res = res.concat(val);
      });
      return res;
    },

  },
  computed: {
    photosMember() {
      var arrUrl = [];
      Object.values(store.employees).forEach(employer => {
        arrUrl.push(employer.image.url)
      })
      return arrUrl.sort(() => Math.random() - 0.5);
    },
    workersColumns() {
      let array = Object.values(store.employees);
      array = array.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
      //console.log(array)
      let columns = [[], [], []];
      let roundUp = Math.ceil((array.length / 3));
      for (let i = 0; i < 3; i++) {
        columns[i] = Object.values(array).slice(i * roundUp, (i * roundUp) + roundUp);
        //console.log(columns[i]);
      }
      return columns;
    },
    departament() {
      return this.singleArray(Object.values(store.organigrama['2'])).sort(((a, b) => a.order - b.order));
    }
  }
}


</script>

<style scoped lang="scss">
.team {}
</style>